@import "~@ng-select/ng-select/themes/default.theme.css";
:root {
  --accordion-bg-color: rgb(200 227 245 / 10%) ;
  --alert-color: #EDA8A5 ;
  --alert-border-color: #DB524B ;
  --warn-color: #F8D6A1 ;
  --warn-border-color: #F2AE43 ;
  --accept-color: #ABDCAB ;
  --accept-border-color: #58B957 ;
  --dark-gray: #424242 ;
  --pretest: #7E93EE ;
  --posttest: #1E3BB8;
  --primary-color: #0054A6 ;
  --primary-action-color: #0095DA ;
  --primary-action-color-darker: #0175A9 ;
  --primary-action-color-lighter: #0095DA0D ;
  --bs-btn-hover-bg: var(--primary-action-color-darker) ;
  --bs-btn-disabled-bg: #8EAAB7 ;
  --bs-btn-disabled-color: #FFF ;
}

section
{
  margin-bottom: 30px ;
}

a:hover {
  color: var(--primary-action-color) ;
}

a[href^="tel:"]:before {
  content: "\260E";
  display: inline-block;
  margin-right: 0.5em;
}

a[href^="mailto:"]:before {
  content: "\2709";
  display: inline-block;
  margin-right: 0.5em;
}

button {
  font-family: "Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif !important ;
}

input:checked[type=checkbox],
input:checked[type=radio]{
  accent-color: var(--primary-action-color) !important;
  background-color: var(--primary-action-color) !important;
  border-color: var(--primary-action-color-darker) !important;
}

.table>tbody>tr>td {
  vertical-align: middle ;
}

.header {
  display: flex ;
  align-items: stretch ;
  background-color: #FFF ;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.2);
  height: 80px ;
  position: relative ;
  z-index: 10 ;
}

.small-text {
  font-size: 0.85em;
}

/*
    Management login screen
*/
.nopadding {
  padding: 0 !important;
  margin: 0 !important; }

.inner-addon {
  position: relative; }

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  padding: 9px;
  pointer-events: none; }

/* align icon */
.left-addon .glyphicon {
  left: 0px; }

.right-addon .glyphicon {
  right: 0px; }

.left-addon .input-icon {
  position: absolute ;
  top: 7px ;
  left: 10px ;
  color: #333 ;
}

/* add padding  */
.left-addon input {
  padding-left: 30px; }

.right-addon input {
  padding-right: 30px; }

.top-addon {
  padding-top: 2%;}

.bottom-addon {
  padding-bottom: 2%;}

.bold
{
  font-weight: bold ;
}

.center {
  text-align: center ;
}

.flex
{
  display: flex ;
}

.column-gap-5 {
  column-gap: 5px ;
}

.column-gap-10 {
  column-gap: 10px ;
}

.column-gap-15 {
  column-gap: 15px !important ;
}

.row-gap-5 {
  row-gap: 5px !important ;
}

.row-gap-0 {
  row-gap: 0 !important ;
}

.row-reverse {
  flex-direction: row-reverse !important;
}

.gap-0 {
  gap: 0 ;
}

.instruction
{
  color: #999;
  font-size: 0.90em;
  margin-top: -30px;
  padding-left: 0;
  text-align: left;
}

.caps-lock-warning {
  display: flex ;
  background-color: rgb(255 226 159);
  border-radius: 5px ;
  color: rgb(103, 76, 12) ;
  font-size: 0.90rem ;
  font-weight: bold ;
  margin-top: -30px ;
  gap: 5px ;
  padding: 5px ;
  opacity: 80%;
  text-wrap: nowrap;
}

.caps-lock-icon {
  position: absolute;
  left: 90%;
  top: 30%;
  transform: translateY(-50%);
  color: rgb(223, 170, 47);
}

.center
{
  text-align: center ;
}

.well-login {
  background: #FFFFFF;
  border: 1px solid #979797;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 19px;
  margin: auto;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  overflow: auto ;
}

.fil-brand-wrapper {
  display: flex ;
  width: 15%;
  height: 100%;
}

.fil-logo {
  padding: 15px;
  height: 100%;
  width: 100%;
}

.fil-logo img {
  height: 100% ;
  width: 100% ;
}

.login-clever {
  display: flex ;
  justify-content: space-around ;
  align-items: center ;
  max-width: 100%;
  height: auto;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  position: relative;
  margin-top: 10%;
  text-align: center;
 }

.btn-clever {
  color: #FFF ;
  background-color: #6FC3EA;
  border-color: #47BDF3 ;
}

.btn-clever:hover {
  color: #FFF !important;
  background-color: #51A4CB ;
  border-color: #007AB3 ;
}

/* Override Bootstrap success button color */
.btn-success,
.btn-success:disabled {
  background-color: #5cb85c ;
  border-color: #429e42;
}

.btn-success:hover {
  background-color: #3f8d3f ;
  border-color: #246824 ;
}

.btn-icon {
  padding: 2px 10px 2px 10px !important ;
}

.exit-menu {
  /* display: none; */
  background: url(../assets/images/clear-24px.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
  margin-top: 25px;
  height: 25px;
  width: 25px;
  border: 0px;
  outline: none;
}

.hamburger-menu {
  /* display: none; */
  background: url(../assets/images/menu-24px.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 15px;
  margin-top: 25px;
  height: 25px;
  width: 25px;
  border: 0px;
  outline: none;
}

.wordflight-logo {
  background: url(../assets/images/wordflight_hrz_color_pos_rgb.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  border: none !important;
  background-color: transparent !important;
}

.diagnosticproduct-logo {
  background: transparent;
  background-size: cover;
  padding: 15px;
  height: 150px;
  width: 200px;
  display: inline-block;
}

.diagnosticproduct-header-management {
  background-image: linear-gradient(-180deg, #A3D7DB 2%, #50AEA7 87%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 80px;
  text-align: center;
  overflow: hidden;
  z-index: 2; }

.diagnosticproduct-plus-school-container {
  float: left;
  text-align: left;
  /*position: relative;*/
  display: inline-block;
}

.diagnosticproduct-management-content {
  position:absolute;
  top: 80px;
  left: 15%;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
}


.purchase-background {
  background-image: linear-gradient(-180deg, #A3D7DB 2%, #50AEA7 87%);
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  position:absolute;
}

.purchase-dialog-background-reports {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  background-image: linear-gradient(-180deg, #A3D7DB 2%, #50AEA7 87%);
  opacity: 0.7;
  position: absolute;
}

.purchase-dialog-container-reports {
  max-height: 70%;
  max-width: 70%;
  height: 50%;
  width: 50%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 20%;
  left: 25%;
  background-image: linear-gradient(180deg, #A3D7DB 2%, #50AEA7 86%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 2px solid #4A4A4A;
}

.purchase-dialog-top-container {
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.purchase-dialog-success-icon {
  background-image: url(../assets/images/iconPurchaseSuccessful.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  height: 45px;
  width: 45px;
}

.purchase-dialog-fail-icon {
  background-image: url(../assets/images/iconPurchaseFailed.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  height: 45px;
  width: 45px;
}

.purchase-dialog-title-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.purchase-dialog-text {
  font-size: 24px;
  font-weight: normal;
  color: #4A4A4A;
  margin: 20px;
  text-align: center;
}

.purchase-dialog-text-bold {
  font-size: 24px;
  font-weight: bold;
  color: #4A4A4A;
  margin: 20px;
  text-align: center;
}

.purchase-dialog-button {
  background-color: #428BCA;
  color: white;
  font-size: 16px;
  min-width: 30%;
  margin-top: 20px;
}

.purchase-outer-container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  overflow: auto;
  background-color: white;
}

.purchase-logo-container {
  display: flex;
  justify-content: center;
}

.purchase-logo {
  width: 100%;
  height: 100%;
  max-height: 100px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.purchase-inner-container {
  background-color: #EAEAEA;
  border: 1px solid #DDDDDD;
  border-radius:5px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.purchase-left-column {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.purchase-subscription-type {
  background-color: white;
  border: 4px solid rgba(28, 50, 28, 0.80);
  border-radius:5px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
}

.purchase-subscription-type-header {
  background-color: rgba(160, 208, 175, 0.80);
  border-bottom: 1px solid black;
  font-size: 15px;
  height: 30px;
  text-align: left;
  display: flex;
  padding-left: 10px;
  padding-top: 5px;
}

.purchase-price-option {
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: center;
  align-items: baseline;
}

.purchase-price {
  font-size: 28px;
  color: rgba(28, 50, 28, 0.80);
  margin: 0px;
}

.purchase-description {
  font-size: 13px;
}

.purchase-price-and-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5px;
}

.purchase-coupon-container {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.purchase-testimonials {
  background-color: rgba(186, 206, 227, 0.80);
  border: 5px solid rgba(23, 71, 96, 0.80);
  border-radius:10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
}

.purchase-testimonial-text-container {
  display: flex;
  flex-direction: column;
}

.purchase-testimonial-text {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}

.purchase-testimonial-signature
{
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  float: right;
}

.purchase-bold-info {
  font-size: 12px;
  font-weight: 800;
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
  text-align: left;
  margin-bottom: 10px;
}

.purchase-small-info {
  font-size: 10px;
  color: #808080;
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
  text-align: left;
  margin-bottom: 20px;
}

.purchase-right-column {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.purchase-account-and-payment {
  background-color: white;
  border: 4px solid rgba(56, 133, 128, 0.80);
  border-radius:5px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
}

.purchase-account-and-payment-header {
  background-color: rgba(145, 204, 200, 0.80);
  border-bottom: 1px solid black;
  font-size: 15px;
  height: 30px;
  text-align: left;
  display: flex;
  padding-left: 10px;
  padding-top: 5px;
}

.purchase-account-info {
  margin: 5px;
  margin-top: 20px;
  border: 1px solid #DDDDDD;
  border-radius:5px;
  font-size: 18px;
  line-height: inherit;
  color: #808080;
}

.purchase-form-group {
  display: flex;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
  color: black;
}

.purchase-cvv-group {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: black;
  margin-left: 20px;
}

.purchase-form-group-error {
  display: flex;
}

.purchase-validation-error {
  width: 100%;
  text-align: left;
  margin: 0px;
  font-size: 10px;
}

.purchase-label {
  width: 200px;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
}

.purchase-label-blank {
  width: 200px;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
}

.purchase-input-box {
  height: 28px;
  margin-right: 5px;
}

.purchase-label-cvv {
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
}

.purchase-group-border {
  border: 1px solid #DDDDDD;
  border-radius:5px;
  margin: 10px 5px;
  padding: 0 5px 5px 5px !important;
}

.purchase-legend {
  width: auto;
  border-bottom: none;
  font-size: 18px;
  line-height: inherit;
  color: #808080;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 0px;
}

.purchase-expiration-and-cvv {
  width: 100%;
  display: flex;
}

.purchase-cancel-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
}

.purchase-cancel-left-side {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}

.purchase-easy-cancellation-text {
  color: #913740;
  font-size: 18px;
  text-align: center;
}

.purchase-small-info-centered {
  font-size: 10px;
  color: #808080;
  text-align: center;
  margin-bottom: 5px;
}

.purchase-submit-button {
  background-color: #428BCA;
  color: white;
  font-size: 16px;
  min-width: 150px;
}

.purchase-credit-card-info {
  display: flex;
}

/*----------------------------------------------*/
@media screen and (max-width: 800px){
  .decoding-border-red {
    width:98% !important;
    height: 88% !important;
    margin-bottom: 10%;
  }

  .decoding-border-yellow {
    width:98% !important;
    height: 88% !important;
    margin-bottom: 10%;
  }

  .decoding-border-green {
    width:98% !important;
    height: 88% !important;
    margin-bottom: 10%;
  }

  .automaticity-border-red {
    width:98% !important;
    height: 88% !important;
    margin-bottom: 25%;
    margin-left: 1%;
  }

  .automaticity-border-yellow {
    width:98% !important;
    height: 88% !important;
    margin-bottom: 25%;
    margin-left: 1%;
  }

  .automaticity-border-green {
    width:98% !important;
    height: 88% !important;
    margin-bottom: 25%;
    margin-left: 1%;
  }

  .sidebar-item-mobile-hidden {
    visibility: hidden;
  }

  .sidebar-item-full-hidden {
    font-size: 1.75rem;
    padding-left: 20%;
    color: white;
    margin-top: 2px;
    visibility: visible !important;
  }

  .purchase-dialog-success-icon {
    width: 50%;
    height: 50%;
  }

  .purchase-dialog-fail-icon {
    width: 50%;
    height: 50%;
  }

  .purchase-dialog-text {
    font-size: 14px;
    margin: 10px;
  }

  .purchase-dialog-text-bold {
    font-size: 14px;
    font-weight: bold;
    margin: 10px;
  }

  .purchase-testimonials{ display: none !important; }

  .purchase-outer-container {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right: 4px;
    overflow: auto;
    background-color: white;
  }

  .purchase-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
  }

  .purchase-left-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  .purchase-right-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  .purchase-inner-container {
    border: 1px solid #DDDDDD;
    overflow: scroll;
    flex-direction: column;
    align-items: center;
  }

  .purchase-form-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .purchase-label {
    width: 100%;
    text-align: left;
  }

  .purchase-label-blank {
    width: 20px;
    padding-right: 0px;
  }

  .purchase-form-group-error {
    display: flex;
    width: 100%;
    text-align: center;
  }

  .purchase-account-and-payment {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 40px;

  }
}
/*----------------------------------------------*/

.diagnostic-details-container {
  position:absolute;
  top: 82px; /* pulled down too far to show a little border */
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: white;
  height: 92%;
}

.diagnosticproduct-management-shaded-bar {
  background-color: #D3D3D3;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #ddd;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.diagnosticproduct-management-text-button-bar {
  padding: 10px ;
  overflow: hidden;
}

.diagnosticproduct-management-text-bar {
  float: left;
  padding-left: 10px;
}

.diagnosticproduct-management-button-bar {
  padding-right: 10px;
  display: flex ;
  column-gap: 10px ;
}

.diagnosticproduct-management-table {
  border: 1px solid #ddd;
}

.diagnosticproduct-management-table .row-controls {
  display: flex ;
  column-gap: 5px ;
  align-items: center ;
}

.diagnosticproduct-management-borderless-table tr td {
  border: none !important;
}

.diagnosticproduct-management-assessment-score-table-header {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #808080;
}

.diagnosticproduct-management-assessment-score-footer {
  text-align: center;
  color: #808080;
}

tr td .diagnosticproduct-management-assessment-score-table-cell {
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd !important;
}

.diagnosticproduct-management-table-header {
  background-color: #D3D3D3;
  color: black;
}

.clever-password {
  background-image: url(../assets/images/Clever-meta-clever.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}

.clever-password-list {
  background-image: url(../assets/images/Clever-meta-clever.png);
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: left;
}

.vertical_text_container {
  display:inline-block;
}


/* Management styles */

.diagnosticproduct-header-text-management {
  font-weight: bold;
  font-size: 48px;
  color: #4A4A4A;
  letter-spacing: 7.86px;
  display: inline-block;
  margin-top: 15px;
  margin-left: 30px;
}

.forgot-password-link {
  /*font-family: Helvetica;*/
  font-family: "Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif;
  font-size: 14px;
  color: #5cb85c;
  float: right;
  text-decoration: none ;
}

.forgot-password-link:hover {
  cursor: pointer;
}

.back-to-login-link {
  font-size: 14px;
  color: #5cb85c;
  float: left;
}

.back-to-login-link:hover {
  cursor: pointer;
}

.resource-category-btn{
  width: 28%;
  height: 30%;
  display: inline-flex;
  margin: 20px;
  flex-direction: column;
}


/* SHARED */
.pointer {
  cursor: pointer ;
}

.grow {
  flex-grow: 1 ;
}

.grade-level-text {
  /*font-family: Helvetica;*/
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Arial", "Helvetica", sans-serif;
  font-weight: bold;
  font-size: 12px;
  font-size: 0.75vw;
  color: #4A4A4A;
  letter-spacing: 1.95px;
  margin-left: 30px;
  margin-top: -10px;
}
.grade-level-text-management {
  /*font-family: Helvetica;*/
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Arial", "Helvetica", sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #4A4A4A;
  letter-spacing: 1.95px;
  margin-left: 8vw;
  margin-top: 10px;
}

.user-welcome,
.district-welcome {
  flex: 1 ;
}

.district-welcome {
  text-align: center ;
}

.welcome-username-management,
.district-welcome {
  display: flex ;
  align-items: center ;
  /*font-family: Helvetica;*/
  font-weight: bold;
  font-size: 24px;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  margin-left: 8vw;
  margin-top: 0px;
}

.user-info-wrapper {
  display: flex ;
  align-items: stretch ;
  justify-content: space-between ;
  width: 100% ;
}

.district-welcome {
  text-align: center ;
}

.management-logout-button-mobile {
  display: none;
}

.management-logout-button,
.management-logout-button:active,
.management-logout-button:focus,
.management-logout-button:hover,
.header-button,
.header-button:active,
.header-button:focus,
.header-button:hover {
  border: 3px solid #4A4A4A;
  border-radius: 12px;
  background-color: transparent;
  outline: none;
  margin-right: 10px;
}

.management-logout-button-adjust-with-settings,
.header-button-adjust-with-settings {
  margin-top: -15px;
}

.management-logout-button-adjust-without-settings,
.header-button-adjust-without-settings {
  margin-top: 18px;
}

.logout-button, .logout-button:active, .logout-button:focus, .logout-button:hover {
  border: 3px solid #4A4A4A;
  border-radius: 12px;
  float: right;
  margin-right: 30px;
  margin-top: 12%;
  vertical-align: middle;
  background-color: transparent;
  outline: none; }

.logout-text {
  /*font-family: Helvetica;*/
  font-family: "Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif;
  font-weight: bold;
  /*font-size: 24px;*/
  font-size: 1.5vw;
  color: #4A4A4A;
  letter-spacing: 3.93px; }

.logout-text-management,
.header-text-management {
  /*font-family: Helvetica;*/
  font-family: "Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #4A4A4A;
  letter-spacing: 3.93px; }

.management-login {
  /*font-family: Helvetica;*/
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Arial", "Helvetica", sans-serif;
  font-weight: 700 ;
  color: #000000;
  margin-top: 20px ;
  margin-bottom: 10px ;
}

.settings-button {
  background-image: url(../assets/images/settingsBttn.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  display: inline-block ;
  margin-right: 15px;
  margin-top: 25px;
  height: 25px;
  width: 25px;
  border: 0px;
  outline: none;
}

.settings-input-text {
  display: inline-block;
  width:50%;
}

.show-password-button {
  background-image: url(../assets/images/iconEye.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  height: 25px;
  width: 25px;
  top: 10px;
  left: -35px;
  position: relative;
  border: 0px;
  outline: none;
}

.checkbox {
  /*font-family: "Helvetica";*/
  font-family: "Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif;
  font-size: 14px;
  color: #000000; }

.remember-me-checkbox {
  border: 1px solid #CCCCCC;
  border-radius: 2px; }

.footer {
  right: 0;
  padding-right: 2%;
  position: absolute;
  bottom: 0; }

/* Management home screen */
.accordion {
  --bs-accordion-btn-bg: #ffffff;
  --bs-accordion-active-bg: #ffffff;
  --bs-accordion-active-color: #616161;
  --bs-accordion-bg: #ffffff;
  --bs-accordion-color: #616161;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-btn-focus-border-color: unset ;
  --bs-accordion-btn-icon-transform: rotate(90deg) ;
}

.accordion-collapse {
  padding: 10px;
  border-top: 1px solid rgb(234 234 234) !important ;
}

.accordion-item {
  border: none;
  background-color: var(--accordion-bg-color) ;
  border-bottom: 1px solid rgb(234 234 234) ;
}

.accordion-button {
  column-gap: 10px ;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: #616161 ;
  justify-content: space-between;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0 ;
  border-top-right-radius: 0 ;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0 ;
  border-bottom-left-radius: 0 ;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
  /*content: "\25B8";*/
  transition: all 0.5s;
  scale: 0.8;
}

.accordion-button.collapsed:hover {
  background-color: #efeeee;
  transition: all 0.5s;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'/%3E%3C/svg%3E");
  transition: all 0.5s;
  scale: 0.8 ;
}

.accordion-item:first-of-type .accordion-item:last-of-type .accordion-button {
  border-radius: 0;
}

.role-selector {
  font-size: 1.25rem;
  font-weight: bold;
  color: #616161 ;
}

.role-selector-wrapper {
  background-color: #DBEAA6 ;
  border-top-left-radius: 0 !important ;
  border-top-right-radius: 0 !important ;
  padding: 10px ;
}

#wrapper {
  padding-left: 250px;
  transition: all 0.4s ease 0s; }

#sidebar-wrapper {
  top: 80px;
  /* overflow: auto; */
  bottom: 0;
  background-color: #FFF ;
  /* max-height: 100vh; */
  transition: all 0.4s ease 0s;
}

#sidebar-wrapper a,
#sidebar-wrapper a:hover {
  text-decoration: none ;
  color: #4A4A4A;
  padding-left: 5px ;
}

.sidebar-revert {
  padding-left: 30px !important ;
}

.sidebar-nav {
  top: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Arial", "Helvetica", sans-serif;
}

.sidebar-nav > .sidebar-brand {
  height: 100%;
  display: flex;
  font-size: 1.5rem;
  line-height: 60px;
  font-weight: 700;
  color: #4A4A4A;
  align-items: center;
  margin-left: 10px;
}

.sidebar-icon {
  height: 100%;
  font-size: 1.5rem;
  padding-left: 10px;
  text-align: left;
  font-weight: 700;
  color: #4A4A4A;
}

.sidebar-nav.foundations-user > .sidebar-brand:not(:first-child) {
  line-height: initial ;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999; }

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none; }

.sidebar-nav > .sidebar-brand img {
  width: 30px ;
}

.sidebar-nav ul {
  list-style: none ;
  padding-left: 0 ;
}

.sidebar-section {
  margin-top: 20px ;
}

.sidebar-nav.foundations-user .sidebar-section {
  margin-left: 20px ;
}

.sidebar-header {
  font-size: 1.25rem;
  padding-left: 10px;
  font-weight: bold ;
  margin-left: 5%;
  margin-top: 5%;
  color: var(--primary-color) ;
}

.sidebar-subheader {
  font-size: 1.15rem;
  font-weight: 700 ;
  color: #0054a6 !important ;
}

.report-type {
  text-transform: uppercase !important ;
}

.sidebar-subtitle {
  width: 85%;
  font-size: 1.75rem;
  font-weight: bold;
  padding-left: 15%;
  color: #4A4A4A;
  display: inline-block;
  margin-top: 5%;
  background-color: transparent;
  border-color: transparent;
  text-align: left;
}

.sidebar-subtitle:hover {
  cursor: default;
}

.sidebar-subtitle:focus {
  outline: transparent;
}

.dropdown-btn {
  width: 95%;
  font-size: 1.75rem;
  font-weight: bold;
  padding-left: 13%;
  color: #4A4A4A;
  display: inline-block;
  margin-top: 10px;
  background-color: transparent;
  border-color: transparent;
  text-align: left;
  cursor: pointer;
  outline: none;
}

.dropdown-btn:hover {
  cursor: default;
}

.dropdown-btn:focus {
  outline: transparent;
}

.dropdown-container {
  display: none;
  background-color: transparent;
  padding-left: 5%;
}

.sidebar-arrow{
  height: 100%;
  width: 5%;
  background-color: transparent;
  border-color: transparent;
  padding: 0px;
  text-align: left;
  border-width: 0px;
  float: right;
}

.sidebar-arrow:focus {
  outline: transparent;
}

.sidebar-item {
  display: block ;
  font-size: 1.1rem;
  font-weight: 700 ;
  color: #4A4A4A ;
  text-transform: capitalize ;
  text-indent: 1em;
  text-decoration: none;
}

.sidebar-item a {
  color: #4A4A4A ;
  text-decoration: none ;
  transition: all 0.25s;
  text-indent: 1.5rem;
  transition: all 0.25s;
}

.sidebar-item a:hover,
.sidebar-item:hover {
  color: #ffffff ;
  background-color: var(--primary-color);
  border-radius: 6px ;
  text-decoration: none ;
  transition: all 0.25s;
}

.sidebar-item-mobile-hidden {
  font-size: 1.25rem;
  padding-left: 20%;
  color: white;
}


.sidebar-item-full-hidden {
  visibility: hidden;
}

.sidebar-item-disabled {
  color: #545454;
}

.sidebar-item:hover {
  cursor: pointer;
}

.sidebar-item-focus {
  background-color: #95caed !important ;
  border-radius: 6px ;
  color: #FFF !important;
  text-indent: 2.0rem !important;
  transition: 0.25s all;
}

.sidebar-subitem {
  font-size: 1.2rem;
  margin-left: 20%;
}

.sidebar-separator {
  height: 10px;
  border-bottom: 1px solid #999 ;
  width: 80%;
  margin-left: 14%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.sidebar-nav.foundations-user .sidebar-separator {
  margin-left: auto ;
  margin-right: auto ;
  width: 95% ;
}

.sidebar-separator-category {
  height: 10px;
  border-bottom: 1px solid #37958E;
  width: 80%;
  margin-left: 14%;
  margin-bottom: 12px;
}

/* Enrollment screen */
.enroll-content {
  margin-top: 10%; }

.help-block {
  color: #d00;
  margin-top: 5px ;
}

.diagnosticproduct-badge {
  background: rgb(161, 207, 221); color: black
}

.diagnosticproduct-number-of-licenses-block {
  width:20%;
  height:35px;
  padding: 5px 0 ;
  background: rgb(161, 207, 221);
  text-align: center;
  margin: auto;
}

.number-of-licenses-block {
  width:50%;
  height:25px;
  background: rgb(161, 207, 221);
  text-align: center;
}

.label.diagnosticproduct-label {
  background-color: #abeceb;
  color: #365251;
}

.diagnosticproduct.assessments-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 12px;
  margin-bottom: 3em;
}

.form-group.checkbox-group {
  padding-top: 0.8em;
}

label.checkbox {
  font-weight: 700;
}

.batch-enroll-preview-header {
  padding-right: 10px;
}

#batch-error {
  margin-top: 15px ;
}

/* Student screen login */

.no-padding {
  padding: 0 !important;
  margin: 0 !important; }

.login-form {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  margin: auto;
  top: 12vh;
  position: relative;
  width: 500px;
  padding-top: 10px;
}

.btn-login, .btn-login:active, .btn-login:focus, .btn-login:hover {
  border: 3px solid #50AEA7;
  border-radius: 12px;
  background-color: transparent;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
 }

.btn-login-text {
  font-weight: bold;
  font-size: 24px;
  color: #50AEA7;
  letter-spacing: 3.93px; }

.student-form-group {
  display: inline-block;
  vertical-align: middle;
  width: 45%;
  margin-bottom: 15px;
}

.form-group {
  padding-bottom: 30px; }

.form-group-no-margin-bottom {
  padding-bottom: 30px;
  margin-bottom: 0px;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif !important ;
  font-size: 14px ;
}

.viewport-1
{
  background-size: cover;
  width: 100vw;
  height: 75vw;
  max-height: 100vh;
/*max-height: 600px;*/
  max-width: 150vh;
  margin: auto;
  position: absolute;
  padding: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;  /* no scrollbars */
}

.content-div
{
  width: 100%;
  height: 80%;  /* in case css3 not supported */
  height: calc(99% - 10vw);  /* height of header and task bar and a little less to acocunt for margin */
  position: relative;
  overflow: hidden;
}

.content-wrapper {
  column-gap: 30px ;
  display: flex ;
  /*height: 100vh ;*/
  height: 100% ;
  /*max-height: calc(100vh - 80px);*/
  max-height: calc(100vh - 82px);
  /*overflow: auto;*/
}

.content-wrapper.dark-background {
  background-color: #EEE ;
}

.main-content {
  flex-grow: 4 ;
  padding: 0 30px 0 0 ;
  overflow-y: auto ;
  /* overflow: auto ; */
  /* max-height: 90vh ; */
  width: 100% ;
}

.sidebar-content {
  background-color: #FFF ;
  width: 22% ;
  height: 100vh ;
  max-height: calc(100vh - 80px);
  overflow-y: auto ;
}

.sidebar-content.hidden {
  width: 0 ;
}

.exit-video {
  width: 40px;
  height: 40px;
  background-image: url(../assets/images/closeBttn.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  float: left;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.videogular-container {
  max-height: 90%;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 10%;
  left: 10%;
  text-align: center;
  margin-right: 10%;
}

.video-background {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  background-color: #1D5551;
  opacity: 0.9;
  position: absolute;
}

.video-container {
  height: 90%;
  width: 90%;
}

.center-videogular-container {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}

video {
  height: 100%;
  width: 100%;
}


.full-opacity {
  opacity: 1;
}

.inner-div
{
  width: 100vw;
  height: 75vw;
  max-height: 100vh;
  max-width: 150vh;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  pointer-events: none;
}

/* Link Clever account page */
.link-container {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.link-container .row {
  padding: 20px;

}

.link-container .button-row {
  color: white;
}

.link-container .create-button-row {
  padding: 45px 20px 20px 20px;
}

.link-container .divider {
  display: flex;
  flex-direction: column;
  padding: 5px 0px;
}

.link-choice {
  flex: 1 1 0px;
  margin: 0 20px;
}

.vert {
  height: 40%;
  width: 1px;
  background: black;
  margin-left: 50%;
}


/* Destination buttons on map screen */
.completed {
  -webkit-filter: grayscale(1);
  filter: grayscale(1); }




  /*
    Allow angular.js to be loaded in body, hiding cloaked elements until
    templates compile.  The !important is important given that there may be
    other selectors that are more specific or come later and might alter display.
   */
  [ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
  }

.svg {
  width: 100%;
  height: auto;
}

/* Upload File */
.input-file {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.input-file + label {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  color: white;
  background-color: rgb(141, 141, 141);
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
}

.input-file:focus + label,
.input-file + label:hover {
  background-color: rgb(121, 121, 121);
}

/* Reports section */
.clearing-container {
  clear: both;
}

.show-archived-checkbox {
  float: right;
  width: 175px;
}

.report-section-header {
  background-color: #F7F7F7 ;
  border: 1px solid #DDD ;
  border-radius: 5px 5px 0 0 ;
  color: black;
  padding: 1px 1px 7px 1px ;
  display: flex ;
}

.full-table-border {
  border: 1px solid #ddd;
  width: 75vw;
}

.full-width {
  width: 75vw;
}

.full-screen-width {
  width: 100%;
}

.disabled-report {
  opacity:0.5;
  background-color:#808080;
  width: 100%;
  height:100%;
  z-index:10;
  left: 19%;
  position:fixed;
}

/* NEW REPORTS */
.header-container{
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 10px 0;
}

.report-icon{
  display: flex;
  align-items: center;
  width: 160px;
  height: 3%;
}

.report-icon-img{
  display: inline-block;
}

.report-icon-label{
  display: inline-block;
  margin: 0 !important;
  padding-left: 5%;
}

.selection-container{
  width: 75%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.selection-item{
  display: inline-block;
  padding-left: 10px;
  margin-right: 2%;
}

.selection-item-input{
  display: inline-block;
  width: 15%;
  padding-left: 10px;
}

.top-table-section {
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
}

.chart-labels-container {
  display: inline-block;
  width: 20%;
}

.compare-table-header {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #ddd;
  color: #808080;
  padding: 10px;
}

.compare-table-cell {
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd !important;
  line-height: 3rem ;
}

.test-compare-selection-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 45%;
  min-width: 150px;
}

.test-selection-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 5px;
  width: 100%
}

.test-selection-row .compare-input {
  padding-right: .5em;
}

.test-selection-dropdown {
  width: 65%;
  margin-bottom: 3px;
}

.test-selection-label {
  min-width: 80px;
  padding-right: 8px;
}

.pie-score {
  display: inline-block;
  position: absolute;
  right: -18%;
}

.pie-chart-container{
  height: 100%;
  width: 52%;
  margin: auto;
}

.pie-footer{
  text-align: center;
  color: #808080;
}

.chart-background {
  background-color: #D8D8D8 ;
  padding: 1% 0% 0% 0% ;
  height: 51vh ;
}

.legend-green {
  background-image: url(../assets/images/reportIcons/iconScoreGreen.svg);
  background-repeat: no-repeat;
  padding-bottom: 2%;
  vertical-align: middle;
  padding-left: 2%;
}

.legend-range{
  padding: 3%;
  color: #58BA57;
  font-size: 13px;
}

.green-color{
  color: #58BA57;
}

.yellow-color{
  color: #F2AE43;
}

.red-color{
  color: #F34336;
}

.legend-yellow {
  background-image: url(../assets/images/reportIcons/iconScoreYellow.svg);
  background-repeat: no-repeat;
  padding-bottom: 2%;
  padding-left: 2%;
}

.legend-red {
  background-image: url(../assets/images/reportIcons/iconScoreRed.svg);
  background-repeat: no-repeat;
  padding-bottom: 2%;
  padding-left: 2%;
}

.recommended-group {
  color: var(--primary-action-color) ;
  font-size: 0.75rem ;
  margin-bottom: 5px;
  margin-top: -5px ;
}

.recommended-wrapper {
  border-width: 3px ;
  border-color: var(--primary-action-color) ;
  border-style: solid ;
  border-radius: 10px ;
  padding: 5px ;
  margin-bottom: 10px ;
  background-color: var(--primary-action-color-lighter) ;
}

.pie-legend {
  position: relative;
  padding: 2px 20px 5px 30px ;
  white-space: nowrap;
  font-size: 13px;
}

.legend-img {
  display: none;
  margin-top: -25px;
}

.modal-dialog {
  background-color: #FFFFFF;
  border-radius: 10px ;
  padding: 15px 15px 20px 15px ;
  position: absolute ;
  top: 100px ;
  left: 200px ;
  right: 200px ;
}

.dashboard-modal .modal-dialog {
  padding: 0 ;
  height: auto ;
  min-height: auto ;
}

/* ****************************** */
.top-table-border {
  width: 100%;
  height: auto;
  overflow: hidden;
  white-space: nowrap;
  table-layout: fixed;
  border-top: 1px solid rgba(211, 211, 211, 0.8);
  border-bottom: 1px solid rgba(211, 211, 211, 0.8);
}

.top-table-border tbody{
  display: block;
}

.top-table-border td{
  white-space: normal;
}

.top-table-border:last-of-type {
  border-right: none;
}

.top-table-priorities-section {
  width: 60%;
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
}

.top-table-screener-section {
  width: 28%;
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
}

.results-report-header {
  font-weight: bold;
  color: gray;
  margin-top: 10px ;
  margin-bottom: 10px ;
  padding-left: 10px;
}

.top-table-decoding-section {
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
}

.top-table-automaticity-section {
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
}

.top-table-screener-results-section {
  width: 1vw;
  vertical-align: top;
  border-left: 1px solid rgba(211, 211, 211, 0.8);
  padding-right: 5vw;
}

.top-table-screener-results-section td {
  vertical-align: top;
  padding-left: 5px;
}

.pie-img {
  display: none;
}

.diagnosticproduct-management-div-pie {
  margin: 10px;
}

.diagnosticproduct-management-pie-placeholder {
  display: table;
  text-align: center;
  margin: auto;
  padding: 10px;
  color: #808080;
}

.table-header-sortable {
  text-decoration: underline;
  cursor: pointer;
}

.table-section-header {
  background-color: #F7F7F7;
  color: black;
}

.table-section-header-alignment {
  text-align: center;
  border-right: 2px solid rgba(211, 211, 211, .8);
  font-size: 1.2rem;
  vertical-align: middle !important;
}

.table-section-header-alignment:last-of-type {
  border-right: none;
}

.detail-table-section-header-alignment {
  text-align: center;
  font-size: 0.9rem;
  vertical-align: middle !important;
  height: 4vh;
}

.detail-table-section-header {
  border-top: 1px solid #ddd;
  height: 10vh;
}

.select-edit-cell {
  display: flex ;
  justify-content: space-around ;
  align-items: center ;
}

.select-edit-cell input[type=checkbox] {
  margin-top: 0 ;
}

.diagnosticproduct-management-reports-table {
  height: 8vh;
  width: 100%;
}

.access-code-report-header-text {
  margin-left: 10px;
  font-weight: bold;
  color: #808080;
}

.student-dropdown-row {
  padding: 7px;
  display: flex;
}

.student-dropdown-row label{
  padding-right: 8px;
  padding-left: 8px;
}

#student-dropdown {
  min-width: 200px;
}

.student-table-header {
  background-color: rgba(211, 211, 211, .4);
}

.student-table {
  margin-bottom: 0;
}

.student-table th,
.student-table td {
  text-align: center;
}

.objective-prepost-chart-container {
  background-color: rgba(211, 211, 211, .4);
  margin-bottom: 0;
  padding: 1%;
  text-align: center;
  display: flex ;
  justify-content: space-between;
  column-gap: 10px ;
}

.objective-chart-container {
  border: 1px solid #1E3BB8;
  display: inline-block;
  width: 50%;
  text-align: left;
}


.objective-header-div {
  background-color: #D3D7EB;
  height: 45px;
  display: flex ;
  justify-content: space-between ;
  align-items: center ;
}

.objective-chart-header {
  font-weight: bold;
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
}

.chart-header-label {
  font-weight: bold;
  margin-left: 10px;
}

.date-label {
  color: #858585;
  margin-left: 10px;
}

.hide-label {
  visibility: hidden;
}

#objective-dropdown {
  margin: 5px;
  margin-left:2%;
  max-width: 60%;
  display: inline-block;
}

.objective-chart-area {
  padding-top: 20px;
  background-color: white;
}

#objective-chart,
#cumulative-objective-chart {
  margin-top: 10px;
}

.weekly-usage-table {
  background-color: #F7F7F7;
  width: 100%;
  text-align: center;
}

.weekly-usage-stat span {
  font-weight: bold;
  font-size: 20px;
  color: #646464;
}

.cumulative-objective-chart-container {
  border: 1px solid #491446;
  display: inline-block;
  width: 50%;
  text-align: left;
}

.cumulative-objective-header-div {
  background-color: #E2C7E0;
  padding: 5px;
  height: 45px;
  display: flex ;
  justify-content: space-between ;
  align-items: center ;
}

.cumulative-objectives-label {
  background-color: white;
  padding: 4px;
  padding-right: 6px;
  padding-left: 6px;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  min-width: 30%;
  text-align: center;
}

.objective-chart-legend {
  margin-left: 75%;
}

.legend-box {
  height: 18px;
  width: 18px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle;
}

.legend-box.pretest-objective {
  background-color: #7E93EE;
}

.legend-box.posttest-objective {
  background-color: #1E3BB8;
}

.legend-box.pretest-cumulative {
  background-color: #995895;
}

.legend-box.posttest-cumulative {
  background-color: #491446;
}

.legend-label {
  display: inline-block;
  font-weight: bold;
  font-size: 12px;
}

.objective-chart-legend>div {
  margin: 5px;
}

.no-data-label {
  font-weight: bold;
  font-size: 14px;
  background-color: rgba(211, 211, 211, .4);
  padding: 5px;
}

.report-header-labels span {
  font-weight: bold;
}

.manage-subs-header {
  font-weight: bold;
  color: gray;
  padding-top: 10px;
  padding-left: 10px;
}

.schedule-error-msg {
  text-align: left;
  padding-left: 0;
  padding-right:0;
}

.schedule-confirm-text {
  font-weight: normal;
  color: #4A4A4A;
  font-size: 20px;
  letter-spacing: 4.91px;
  position: relative;
  margin: 10%;
  text-align: center;
}

#scheduleTable td,
#scheduleTable th {
  text-align: center;
}

.schedule-table-cell {
  height: 25px;
  vertical-align: middle;
}

.schedule-button {
  color: white;
  padding: 4px 8px 4px 8px;
  font-size: 1rem;
  margin-right: 20px;
}

.schedule-button:hover {
  color: white;
}

.schedule {
  background-color: #6CBF6D;
}

.schedule-button.schedule:hover {
  background-color: #4F9950;
}

.default-schedule {
  background-color: #5496D0;
}

.schedule-button.default-schedule:hover {
  background-color: #3E77AA ;
}

.skip {
  background-color: #AC5D59;
}

.schedule-button.skip:hover {
  background-color: #8C4542 ;
}

.go-back-button {
  width: 10vw;
  background-color: #50AEA7;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 4px 8px 4px 8px;
  margin-top: 7px;
}

.scheduling-button {
  width: auto;
  font-size: 1.1rem;
  background-color: #50AEA7;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
  margin-top: 7px;
  margin-right: 10px;
}

.scheduling-button:hover {
  background-color: #479892;
  color: white;
}

.md-calendar-day-header {
  margin: auto;
}

.schedule-header-container {
  background-color: rgba(211, 211, 211, .4);
  font-weight: bold;
  color: gray;
  font-size: 20px;
}

.schedule-dialog-text {
  font-weight: normal;
  color: #4A4A4A;
  font-size: 18px;
}

.md-datepicker-calendar-pane {
  z-index: 1200;
}

.schedule-dialog {
  width: 70%;
}

.schedule-dialog-confirm-btn {
  width: 40%;
  color:white;
  padding: 4px 8px 4px 8px;
  font-size: 1rem;
  background-color: #6CBF6D;
}

.schedule-dialog-cancel-btn {
  width: 40%;
  color:white;
  padding: 4px 8px 4px 8px;
  font-size: 1rem;
  margin-right: auto !important;
  background-color: #AC5D59;
}

.print-summary-button,
.export-report-button,
.action-button {
  font-size: 1.1rem;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 2px 10px 2px 10px;
  margin-top: 7px;
  margin-right: 10px;
}

.undo-button {
  background-color: var(--primary-action-color);
  color: #FFF ;
}

.print-summary-button:hover,
.action-button:hover{
  background-color: var(--primary-action-color-darker);
  color: white !important;
}

.export-report-button:hover {
  background-color: #489248;
  color: white !important;
}

.print-summary-button,
.action-button {
  background-color: var(--primary-action-color);
}


.export-report-button {
  background-color: #5CB85C ;
}

.print-student-detail-button {
  width: 40%;
  height: 80%;
  background-color: #4694D4;
  border-radius: 4px;
  font-size: 1.25rem;
  color: white;
  border: none;
  /*padding: 1px 1px 1px 1px;*/
  padding: 2px 4px 2px 4px;
  /*margin-right: 45px;*/
  margin-top: 8px;
  float: right;
}

.print-student-detail-button:hover {
  color: white;
}

.return-to-summary-button {
  background-color: var(--primary-action-color);
  border-radius: 4px;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
}

.return-to-summary-button:hover {
  background-color: #3A75A5;
  color: white;
}

.print-selected-button {
  background-color: #4694D4;
  border-radius: 4px;
  color: white;
  padding: 2px 4px 2px 4px;
  /*width: 175px;*/
  width: 15vw;
  font-size: 1.5rem;
  margin: 4% 2% 2% 2%;
}

.print-selected-button:hover {
  color: white;
}

.screener-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.move-selected-button {
  background-color: #4694D4; /*was #50AEA7*/
  border-radius: 4px;
  color: white;
  padding: 2px 4px 2px 4px;
  /*width: 175px;*/
  width: 11vw;
  font-size: 1.1rem;
  margin: 4% 2% 2% 2%;
}

.move-selected-button:hover {
  color: white;
}

.view-detail-button {
  background-color: #4694D4 ;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 2px 7px 2px 7px;
}

.view-detail-button:hover {
  background-color: #3A75A5;
  color: #FFF ;
}

.prev-detail-button {
  background-color: var(--primary-action-color);
  text-align: center;
  vertical-align: middle;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
}

.prev-detail-button:hover {
  color: white;
}

.prev-next-button {
  background-color: var(--primary-action-color);
  text-align: center;
  color: white;
  border: none;
  padding: 2px 4px 2px 4px;
}

.prev-next-button:hover {
  background-color: #3A75A5;
  color: white;
}

.table-row-alignment {
  text-align: center;
}

.teacher-text {
  margin: 25px;
  font-size: 18px;
  text-align: left;
}

.instructional-priorities {
  width: 100%;
  list-style-type: none;
  text-align: left;
  font-size: 1.4rem;
  padding-left: 4%;
}

.dialog-background-reports {
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  background-image: linear-gradient(-180deg, #A3D7DB 2%, #50AEA7 87%);
  opacity: 0.7;
  position: absolute;
}

.dialog-container-reports {
  max-height: 70%;
  max-width: 70%;
  height: 50%;
  width: 50%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 20%;
  left: 25%;
  background-image: linear-gradient(180deg, #A3D7DB 2%, #50AEA7 86%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border: 2px solid #4A4A4A;
}

.save-data-dialog-top-container-reports {
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  /*border-bottom: 2px solid black;*/
  position: absolute;
  overflow: hidden;
}

.save-data-dialog-bottom-container-reports {
  height: 25%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-around;
}

.save-data-dialog-text-reports {
  /*font-size: 30px;*/
  font-size: 1.5vw;
  font-weight: normal;
  color: #4A4A4A;
  letter-spacing: 4.91px;
  position: relative;
  margin-left: 2vw;
  margin-top: 2vh;
  text-align: left;
}

.assign-confirm-student-list{
  height: 60%;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: left;
}

.assign-confirm-text{
  font-weight: normal;
  color: #4A4A4A;
  font-size: 22px;
  letter-spacing: 4.91px;
  position: relative;
  margin: 20px;
  text-align: center;
}

.assign-confirm-dialog-background{
  height: 100%;
  width: 100%;
  top: 0%;
  left: 0%;
  z-index: 1000;
  opacity: 0.8;
  position: absolute;
  background: rgb(206, 206, 206,0.80);
}

.assign-confirm-dialog-container {
  max-height: 100%;
  max-width: 100%;
  height: 35%;
  width: 30%;
  overflow: hidden;
  z-index: 1001;
  position: absolute;
  top: 25%;
  left: 30%;
  background: rgb(255, 255, 255, 0.90);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.yes-dialog-btn, .yes-dialog-btn:hover {
  height: 85%;
  width: 40%;
  background-color: #DEE2E6 !important;
}

.yes-dialog-btn:disabled {
opacity: 0.3;
}

.yes-text {
width: 77%;
font-weight: bold;
color: #146448;
letter-spacing: 3.93px;
line-height: 150%;
font-size: 22px;
display: inline-block; }

.no-dialog-btn, .no-dialog-btn:hover {
height: 85%;
width: 40%;
background-color: #DEE2E6 !important;
}

.no-dialog-btn:disabled {
opacity: 0.3;
}


.no-text {
width: 77%;
font-weight: bold;
vertical-align: center;
color: #185CAC;
letter-spacing: 3.93px;
line-height: 150%;
font-size: 22px;
display: inline-block;}

.confirm-dialog-top-container {
  height: 70%;
  width: 100%;
  overflow: hidden;
}

.confirm-dialog-bottom-container {
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}


.hidden {
  display: none;
}
/* Student detail reports */

.decoding-charts-section {
  background-color: white;
  height: 100%;
  flex:50%;
}

.generalization-charts-section {
  background-color: white;
  height: 100%;
  flex:50%;
}

.automaticity-charts-section {
  background-color: white;
  padding: 0%;
  float: left;
  height: 45vh;
}

.decoding-header-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */
  color: #3D3D3D;
  font-size: 1.05rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.decoding-header-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */
  color: #3D3D3D;
  font-size: 1.05rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}
.decoding-header-green {
  background-color:rgba(88,185,87,0.5); /* #58B957 */
  color: #3D3D3D;
  font-size: 1.05rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.decoding-border-red {
  width:48%;
  margin-right:3%;
  margin-left:1%;
  border: solid #DB524B;
}

.decoding-border-yellow {
  width:48%;
  margin-right:3%;
  margin-left:1%;
  border: solid #F2AE43;
}

.decoding-border-green {
  width:48%;
  margin-right:3%;
  margin-left:1%;
  border: solid #58B957;
}

.generalization-header {
  background-color: lightgray;
  color: #3D3D3D;
  font-size: 1.5rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.generalization-subhead {
  text-align: center;
}

.automaticity-header-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */;
  color: #3D3D3D;
  font-size: 1.05rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.automaticity-header-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */;
  color: #3D3D3D;
  font-size: 1.05rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.automaticity-header-green {
  background-color: rgba(88,185,87,0.5); /* #58B957 */;
  color: #3D3D3D;
  font-size: 1.05rem;
  margin: 0% 0% 1% 0%;
  padding: 2px 4px 2px 4px;
}

.automaticity-border-red {
  width:47%;
  margin-right:1%;
  border: solid #DB524B;
}

.automaticity-border-yellow {
  width:47%;
  margin-right:1%;
  border: solid #F2AE43;
}

.automaticity-border-green {
  width:47%;
  margin-right:1%;
  border: solid #58B957;
}

.automaticity-subhead {
  text-align: center;
  margin-top: 8%;
}

.decodingCategories {
  font-size: 1vw;
  margin-right:1%;
}

.decodingSubheads {
  border: 1px solid black;
  overflow: hidden;
}

.decoding-subhead-consonants {
  background-color: rgba(88,185,87,0.5);
  border-radius: 2px;
  border: 2px solid #58B957;
  height: 1%;
  width: 46.25%;
  font-size: 1rem;
  margin-left: 2vw;
  float: left;
  position: relative;
}

.decoding-subhead-vowels {
  background-color: rgba(88,185,87,0.5);
  border-radius: 2px;
  border: 2px solid #58B957;
  height: 1%;
  width: 46.25%;
  font-size: 1rem ;
  margin-left: 0.25vw;
  float: left;
  position: relative;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.unit-detail-sort-arrow {
  font-size: 10px;
  cursor: pointer;
}

.zone {
  background-color: #DEDEDE;
  border-radius: 3px;
  padding: 1.5%;
}

.score-green {
  background-image: url(../assets/images/reportIcons/iconScoreGreen.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.score-yellow {
  background-image: url(../assets/images/reportIcons/iconScoreYellow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.score-red {
  background-image: url(../assets/images/reportIcons/iconScoreRed.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.screener-results-score {
  padding-left: 20px ;
}

.legend-green {
  background-image: url(../assets/images/reportIcons/iconScoreGreen.svg);
  background-repeat: no-repeat;
  padding-bottom: 2%;
  vertical-align: middle;
  padding-left: 2%;
}

.legend-green-range {
  padding: 3%;
  color: #58BA57;
  font-size: 13px;
}

.legend-yellow {
  background-image: url(../assets/images/reportIcons/iconScoreYellow.svg);
  background-repeat: no-repeat;
  padding-bottom: 2%;
  padding-left: 2%;
}

.legend-yellow-range {
  padding: 3%;
  color: #F2AE43;
  font-size: 13px;
}

.legend-red {
  background-image: url(../assets/images/reportIcons/iconScoreRed.svg);
  background-repeat: no-repeat;
  padding-bottom: 2%;
  padding-left: 2%;
}

.legend-red-range {
  padding: 3%;
  color: #F34336;
  font-size: 13px;
}

.screener-results-img {
  display: none;
}

.legend-score {
  padding-left: 0.5rem;
  vertical-align: middle;
}

.student-score {
  margin: -20px -7px 0px 0;
}

.chart-score {
  margin: -23px 0 0 50px;
}

.icon-img {
  display: none;
  float: left;
  padding-left: 11%;
  margin: 0 -130px 0 0;
}

.chart-img {
  display: none;
}

.scale-container {
  overflow: hidden;
  width: 100%;
  height: 76px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.zone-container {
  width: 47% ;
}

.detail-brackets-img {
  width: 100%;
  padding-left: 2vw;
  padding-right: 1.5vw;
}

.instructional-priority-details {
  float: left;
  margin-left: 2%;
  margin-bottom: 2%;
  width: 48%;
  overflow: hidden;
  display:inline-block;
}

.instructional-priority-links {
  float: left;
  margin-left: 2%;
  width: 40vw;
  overflow: hidden;
}

.consonant-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */
  border-color: rgba(219,82,75,0.5); /* #DB524B */
  color: #3D3D3D;
  text-align: center;
}

.consonant-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */
  border-color: rgba(242,174,67,0.5); /* #F2AE43 */
  color: #3D3D3D;
  text-align: center;
}
.consonant-green {
  background-color:rgba(88,185,87,0.5); /* #58B957 */
  border-color:rgba(88,185,87,0.5); /* #58B957 */
  color: #3D3D3D;
  text-align: center;
}

.vowel-red {
  background-color: rgba(219,82,75,0.5); /* #DB524B */
  border-color: rgba(219,82,75,0.5); /* #DB524B */
  color: #3D3D3D;
  text-align: center;
}

.vowel-yellow {
  background-color: rgba(242,174,67,0.5); /* #F2AE43 */
  border-color: rgba(242,174,67,0.5); /* #F2AE43 */
  color: #3D3D3D;
  text-align: center;
}
.vowel-green {
  background-color:rgba(88,185,87,0.5); /* #58B957 */
  border-color:rgba(88,185,87,0.5); /* #58B957 */
  color: #3D3D3D;
  text-align: center;
}

.enrollment-grade-dropdown
{
  height: 100%;
}

.enrollment-session-time-dropdown
{
  height: 100%;
}

.enrollment-radio-button
{
  border-style:  solid;
  border-width:  1px;
  border-color:  rgba(122, 119, 119, 0.5);
  border-radius: 5px;
  padding-left:  3px;
}

.assign-remove-diagnostic-button {
  height: 25px;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 4px;
  border: none;
  padding: 2px 4px 2px 4px;
}

.assign-remove-diagnostic-button-padded {
  height: 25px;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 4px;
  border: none;
  padding: 2px 4px 2px 4px;
  margin-bottom: 4px;
}

.assign-diagnostic-sub-cell {
  height: 25px;
  display: table-cell;
  vertical-align: middle;
}

.subscription-button {
  width: 65% ;
  font-size: 0.75rem ;
}

/* Teacher Dashboard */
.dashboard-container{
  padding: 20px;
  border: 1px solid #DDDDDD;
  margin-bottom: 20px;
}

.dashboard-label{
  padding: 5px;
  padding-left: 20px;
  background-color: #F7F7F7;
  border: 1px solid #DDDDDD;
  color: #818181;
  font-size: 2rem;
  font-weight: bold;
}

.reports-icon{
  width: 40px;
  height: 40px;
  background-image: url(../assets/images/iconReports.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.reports-text{
  display: inline-block;
  font-size: 3rem;
  font-weight: bold;
}

.reports-label{
  width: 14%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 2%;
}

.district-report-wrapper {
  position: absolute ;
  top: 80px;
  left: 15%;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  background-color: #EEE ;
}

.district-report-header {
  display: flex ;
  justify-content: space-between;
  align-items: center ;
  margin-bottom: 20px ;
}

.district-report-header-group {
  display: flex ;
  column-gap: 30px ;
  align-items: center ;
}

.district-report-header .report {
  font-weight: bold ;
  font-size: 1.25rem ;
  text-transform: capitalize ;
}

.district-report-filter-wrapper {
  background-color: #F7F7F7 ;
  display: flex ;
  justify-content: flex-start ;
  column-gap: 25px;
  margin-top: 30px ;
  margin-bottom: 10px ;
  padding: 15px 25px ;
}

.district-report-row,
.report-row {
  display: flex ;
  gap: 30px 40px ;
  margin-bottom: 30px ;
}

.district-report-row:last-child,
.report-row:last-child {
  margin-bottom: 0 ;
}

.category-report-summary {
  display: flex ;
  column-gap: 45px ;
}

.category-report-summary.chart-summary {
  justify-content: flex-end ;
  margin-top: -50px ;
}

.category-report-summary.center-flex .summary {
  margin-top: -20px ;
}

.category-report-info {
  min-height: 30px ;
  padding: 0 30px ;
}

.district-report-column,
.report-column {
  display: flex ;
  gap: 30px ;
  flex-direction: column ;
}

.district-report-table {
  width: 100% ;
  text-align: center ;
  margin-top: 40px ;
}

.report-table {
  width: 100% ;
  text-align: center ;
}

.report-table.small {
  font-size: 0.75rem ;
}

.district-report-table tbody,
.report-table tbody {
  border-bottom: 1px solid #DDD ;
  width: 100% ;
}

.district-report-table thead,
.report-table thead {
  background-color: #F7F7F7 ;
  border-top: 1px solid #DDD ;
  border-bottom: 1px solid #DDD ;
}

.district-report-table th,
.report-table th {
  font-weight: bold ;
  text-align: center ;
}

.district-report-table th,
.district-report-table td,
.report-table th,
.report-table td {
  padding-top: 8px ;
  padding-bottom: 8px ;
}

.report-table td {
  vertical-align: top ;
  border-bottom: solid ;
  border-bottom-color: #DEE2E6;
  border-bottom-width: 1px ;
}

.district-report-table th:not(:last-child),
.report-table th:not(:last-child){
  border-right: 1px solid #E4E4E4 ;
}

.district-report-content-container {
  background-color: #F7F7F7 ;
  border: 1px solid #979797 ;
  border-radius: 5px ;
  padding: 10px ;
}

.district-report-panel {
  background-color: #FFF ;
  border: 1px solid #DCDCDC ;
  padding: 10px ;
}

.district-report-panel .title,
.report-panel .title,
.section-title {
  display: flex ;
  align-items: center ;
  column-gap: 20px ;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Arial", "Helvetica", sans-serif;
  font-weight: bold;
  font-size: 1.1rem ;
}

.district-report-panel .report-header {
  margin-bottom: 10px ;
}

.report-container {
  border: 1px solid #DDD ;
  border-top: 0px ;
  padding: 10px ;
}

.report-panel {
  background-color: #FFF ;
  border: 1px solid #DCDCDC ;
}

.report-panel .title {
  border-bottom: 1px solid #DCDCDC ;
  padding: 10px ;
}

.report-panel .content {
  padding: 10px;
}

.sortable-report-table-header {
  cursor: pointer ;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
}

.subtitle {
  font-family: "Gotham SSm A", "Gotham SSm B", "Arial", "Helvetica", sans-serif;
  font-weight: normal ;
  color: #858585 ;
}

.gauge {
  display: flex ;
  flex-direction: column ;
  position: relative ;
}

.gauge .graph {
  flex-grow: 1 ;
  display: flex ;
  justify-content: center ;
}

.district-report-panel .title .display-toggle {
  font-size: 2rem ;
  color: #D8D8D8 ;
  cursor: pointer ;
}

.district-report-panel .stat-tile {
  display: flex ;
  gap: 30px ;
  margin-top: 20px ;
  padding: 0 10px ;
}

.vertical-stat {
  display: flex ;
  flex-direction: column ;
  flex: 1 ;
}

.vertical-stat .value-wrapper {
  padding: 5px ;
  display: flex ;
  justify-content: center ;
}

.vertical-stat .value {
  background-color: #F2F2F2 ;
  font-weight: bold ;
  font-size: 1.25rem ;
  text-align: center ;
  padding-top: 3px ;
  padding-bottom: 3px ;
}

.gauge .stat {
  width: 40% ;
}

.stat .stat-value,
.category-report-summary .summary {
  font-weight: bold ;
  font-size: 2rem ;
}

.stat .stat-unit {
  color: #707070 ;
  font-size: 1.1rem ;
  font-weight: normal ;
}

.stat .stat-label,
.category-report-summary .bar-chart-label,
.score-bar .bar-label,
.chart-value {
  color: #7F7F7F ;
}

.category-report-summary .visual {
  flex-grow: 1 ;
  margin-top: 10px ;
  text-align: center ;
}

.visual .bar-chart {
  background-color: #D8D8D8 ;
  border-radius: 3px ;
  margin-bottom: 10px ;
  width: 100%;
  height: 20px ;
}

.visual .bar-chart .bar-chart-value {
  background-color: #3D94D4 ;
  border-radius: 3px ;
  height: 20px ;
}

.split-flex {
  justify-content: space-between ;
}

.space-around {
  justify-content: space-around ;
}

.center-flex {
  align-items: center ;
}

.center-space-flex {
  justify-content: center ;
}

.accent-text {
  color: #4A4A4A ;
  font-weight: normal ;
}

.light-grey {
  color: #808080 ;
}

.gauge .gauge-detail {
  display: flex;
  flex-direction: column;
  flex-direction: column;
  position: absolute ;
  left: 0px;
  bottom: 15px;
  width: 100% ;
}

/* Reports */
.screener-report-summary {
  display: flex ;
  column-gap: 10px ;
  border-left: 1px solid #DDD ;
  border-right: 1px solid #DDD ;
}

.screener-report-chart {
  flex-grow: 2 ;
}

.report-chart-wrapper {
  display: flex ;
  column-gap: 15px ;
  justify-content: space-between ;
}

.limit-chart-width {
  max-width: 60 ;
}

.min-height-25vh {
  min-height: 22vh ;
}

.flex-end {
  align-items: flex-end ;
}

.report-chart-wrapper.compare-wrapper .legend-wrapper {
  margin-bottom: 5px ;
}

.report-chart-legend {
  display: flex;
  flex-direction: column ;
  flex-grow: 1 ;
}

.report-chart-no-data-found {
  text-align: center ;
  color: var(--dark-gray);
  opacity: 0.5;
  font-weight: bold ;
  font-size: 1.25em ;
  margin-bottom: 1em ;
  min-height: 14vh;
  display: inline-grid;
  align-items: center;
  font-style: italic;
}

.report-chart-legend .legend-wrapper {
  display: flex ;
  justify-content: space-between ;
}

.legend-wrapper .report-chart-value {
  width: 25% ;
  text-align: center ;
}

.legend-wrapper.pushed {
  margin-left: 7px ;
}

.legend-wrapper.pushed .report-chart-value {
  margin-right: 7px ;
}

.report-chart-legend .legend-key {
  text-align: center ;
}

.report-chart-wrapper .report-chart-chart {
  flex-grow: 2 ;
}

.screener-report-summary .license-info {
  display: flex ;
  flex-direction: column ;
  align-items: center ;
  padding: 0 10px ;
}

.screener-report-summary .border-right {
  border-right: 1px solid #DDD ;
}

.screener-report-summary .border-left {
  border-left: 1px solid #DDD ;
}

.margin-bottom-1-em {
  margin-bottom: 1em ;
}

.screener-header {
  font-size: 1.25em ;
}

#district-report-screener-enrollment,
#district-report-usage-enrollment,
#district-report-usage-started {
  height: 170px ;
}

#district-report-usage-trend {
  height: 370px ;
}

#district-report-usage-trend #usage-trend-chart {
  height: 100% ;
  position: relative ;
}

#district-report-screener-completion .summary {
  font-weight: bold ;
  color: var(--primary-action-color) ;
}

#district-report-screener-performance {
  height: 100% ;
}

#district-report-screener-performance #performance-data {
  display: flex;
  align-items: center ;
  justify-content: space-around ;
  height: 100% ;
}

#district-report-usage-goal {
  display: flex ;
  column-gap: 30px ;
  flex-direction: row ;
  margin-top: 20px ;
  padding: 0 10px ;
}

#district-report-usage-goal #usage-info {
  display: flex ;
  flex-direction: column ;
  width: 100% ;
}

#district-report-usage-goal #usage-info .usage-row {
  display: flex ;
  flex-direction: row ;
  justify-content: space-between ;
}

#district-report-assessments-charts {
  flex-grow: 1 ;
}

.usage-summary {
  display: flex ;
  column-gap: 25px ;
  background-color: #EEE ;
  border-radius: 10px ;
  margin-bottom: 20px ;
  padding: 20px ;
  text-align: center ;
}

#district-report-usage-goal .usage-statistics {
  display: flex ;
  column-gap: 10px ;
}

#usage-summary .stat-label {
  color: #4A4A4A ;
}

.diagnostic-progress-panel {
  background-color: #EEE ;
  border-radius: 10px ;
  padding: 20px ;
}

.diagnostic-progress-info {
  display: flex ;
  flex-direction: row ;
  align-items: center ;
  justify-content: center ;
  column-gap: 25px ;
}

.diagnostic-progress-info .display {
  margin-left: 50px ;
}

.diagnostic-progress-info .stat-tile {
  margin-top: 0 ;
}

.diagnostic-performance-panel {
  margin: 20px 50px 0 50px ;
  padding: 10px ;
  border: 1px solid #DCDCDC ;
}

.diagnostic-performance-panel.left {
  margin-top: 0 ;
  margin-right: 0 ;
}

.diagnostic-performance-panel.right {
  margin-top: 0 ;
  margin-left: 0 ;
}

.diagnostic-performance-panel div {
  display: flex ;
  flex-direction: row ;
}

.diagnostic-performance-panel div.labels {
  column-gap: 100px ;
  justify-content: center ;
}

.diagnostic-performance-panel div.labels div.perf-label {
  display: flex ;
  flex-direction: column ;
  align-items: center ;
}

.diagnostic-performance-panel div.labels div.perf-label.high-risk .value-range {
  color: #9D342B ;
}

.diagnostic-performance-panel div.labels div.perf-label.some-risk .value-range {
  color: #B28540 ;
}

.diagnostic-performance-panel div.labels div.perf-label.proficient .value-range {
  color: #4F8642 ;
}

.diagnostic-performance-panel div.performance-row-label-group {
  display: flex ;
  flex-direction: column ;
  gap: 0 ;
  align-items: center ;
}

.diagnostic-performance-panel div.performance-row-label-group div.performance-row-label {
  font-weight: bold ;
}

.diagnostic-performance-panel div.performance-row-label-group div.performance-row-value {
  color: #7F7F7F ;
}

.diagnostic-performance-panel div.performance-row {
  justify-content: center ;
  margin-bottom: 0 ;
}

.diagnostic-performance-panel div.performance-row-bars {
  margin-right: 50px ;
  width: 80% ;
  column-gap: 5px ;
}

.diagnostic-performance-panel div.performance-row-bar {
  display: block;
  text-align: center ;
  font-weight: bold ;
  width: 100% ;
}

.diagnostic-performance-panel div.performance-row-bar.high-risk {
  background-color: #CF432F ;
}

.diagnostic-performance-panel div.performance-row-bar.some-risk {
  background-color: #E7AF5F ;
}

.diagnostic-performance-panel div.performance-row-bar.proficient {
  background-color: #5BA548 ;
}

.icon-score
{
  display: flex;
  column-gap: 10px ;
  align-items: center ;
  justify-content: center ;
}

.score
{
  width: 40px ;
}

.score-bar {
  margin-top: 7px ;
  text-align: center ;
}

.score-bar .bar {
  background: linear-gradient(to right, #CF422F 60%, #EBB14E 60% 75%, #5BA548 75%);
  border-radius: 5px ;
  height: 25px ;
  width: 480px ;
  background-color: red ;
  margin-bottom: 10px ;
}

.chart {
  display: flex ;
  column-gap: 10px ;
  align-items: center ;
}

.chart-display {
  width: 150px ;
}

.chart-label {
  display: flex ;
  flex-direction: row ;
  column-gap: 10px ;
}

#performance-data #categories {
  width: 40% ;
}

#categories #proficient {
  background-color: #EDF6EC ;
}

.chart-label .image-label.icon {
  width: 115px ;
}

.stat-tile .image-label.icon,
.chart-label .image-label.icon,
.diagnostic-performance-panel div.perf-label .icon {
  background-repeat: no-repeat ;
  font-weight: bold ;
  padding: 2px 20px 5px 30px;
}

#categories #proficient.stat-tile .icon,
.chart-label .icon.top,
.diagnostic-performance-panel div.perf-label.proficient .icon {
  background-image: url(../assets/images/reportIcons/iconScoreGreen.svg) ;
}

#categories #some-risk.stat-tile .icon,
.chart-label .icon.middle,
.diagnostic-performance-panel div.perf-label.some-risk .icon {
  background-image: url(../assets/images/reportIcons/iconScoreYellow.svg) ;
}

#categories #high-risk.stat-tile .icon,
.chart-label .icon.low,
.diagnostic-performance-panel div.perf-label.high-risk .icon {
  background-image: url(../assets/images/reportIcons/iconScoreRed.svg) ;
}

#categories #some-risk {
  background-color: #FCF6ED ;
}

#categories #high-risk {
  background-color: #F8EAE9 ;
}

#categories #proficient,
#categories #some-risk,
#categories #high-risk {
  display: flex ;
  flex-wrap: wrap ;
  align-items: center ;
  gap: 5px ;
  margin-bottom: 20px ;
  padding-top: 15px ;
  padding-bottom: 15px ;
  padding-left: 20px ;
}

.inline {
  display: inline-block ;
}

.width-10 {
  width: 10% ;
}

.width-14 {
  width: 14% ;
}

.width-15 {
  width: 15% ;
}

.width-20 {
  width: 20% ;
}

.width-25 {
  width: 25% ;
}

.width-35 {
  width: 35% ;
}

.width-40 {
  width: 40% ;
}

.width-50 {
  width: 50% ;
}

.width-60 {
  width: 60% ;
}

.width-65 {
  width: 65% ;
}

.width-70 {
  width: 70% ;
}

.width-75 {
  width: 75% ;
}

.width-80 {
  width: 80% ;
}

.width-90 {
  width: 90% ;
}

.width-100 {
  width: 100% ;
}

.center-text {
  text-align: center ;
}

.right-text {
  text-align: right ;
}

.margin-top-0 {
  margin-top: 0 ;
}

.margin-top-5 {
  margin-top: 5px ;
}

.margin-top-10 {
  margin-top: 10px ;
}

.margin-top-15 {
  margin-top: 15px ;
}

.margin-top-25 {
  margin-top: 25px ;
}

.margin-top-45 {
  margin-top: 45px ;
}

.margin-top-60 {
  margin-top: 60px ;
}

.margin-bottom-0 {
  margin-bottom: 0 ;
}

.margin-bottom-10 {
  margin-bottom: 10px ;
}

.margin-bottom-20 {
  margin-bottom: 20px ;
}

.margin-bottom-25 {
  margin-bottom: 25px ;
}

.margin-bottom-5 {
  margin-bottom: 5px ;
}

.margin-bottom-min {
  margin-bottom: 0.125rem ;
}

.margin-right-5 {
  margin-right: 5px ;
}

.margin-left-3 {
  margin-left: 3px ;
}

.margin-left-5 {
  margin-left: 5px ;
}

.margin-left-50 {
  margin-left: 50px ;
}

.padding-10 {
  padding: 10px;
}

.padding-15 {
  padding: 15px;
}

.padding-top-5 {
  padding-top: 5px ;
}

.padding-top-10 {
  padding-top: 10px ;
}

.padding-top-15 {
  padding-top: 15px ;
}

.padding-bottom-5 {
  padding-bottom: 5px ;
}

.padding-bottom-15 {
  padding-bottom: 15px ;
}

.padding-left-30 {
  padding-left: 30px ;
}

.bg-light-gray {
  background-color: #F2F2F2 ;
}

.dark-gray {
  color: var(--dark-gray) ;
}

.message {
  display: flex ;
  flex-direction: row ;
  column-gap: 15px ;
}

.message .icon {
  font-size: 2em ;
}

.teacher-dashboard-box{
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  border: 5px solid;
  border-radius: 1vh;
  background-color: white;
}

.teacher-dashboard-box-long{
  min-height: 100px;
  width: 100%;
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  align-items: stretch;
  border: 5px solid;
  border-radius: 1vh;
  background-color: white;
}

.teacher-dashboard-box.students-enrolled {
  border-color: rgba(24,71,96,0.80)
}

.teacher-dashboard-box.students-enrolled .dashboard-box-label {
  background: rgba(186,206,227,0.80);
  border-bottom: 1px solid rgba(24,71,96,0.80) ;
}

.teacher-dashboard-box.students-not-started {
  border-color:  rgba(72,15,42,0.80) ;
}

.teacher-dashboard-box.students-not-started .dashboard-box-label {
  background: rgba(236,194,215,0.80);
  border-bottom: 1px solid rgba(72,15,42,0.80) ;
}

.teacher-dashboard-box.weekly-time-goal {
  border-color: rgba(28,50,28,0.80) ;
}

.teacher-dashboard-box.weekly-time-goal .dashboard-box-label {
  background: rgba(160,208,175,0.80);
  border-bottom: 1px solid rgba(28,50,28,0.80) ;
}

.teacher-dashboard-box.average-weekly-usage {
  border-color: rgba(73,19,70,0.80) ;
}

.teacher-dashboard-box.average-weekly-usage .dashboard-box-label {
  background: rgba(214,190,224,0.80);
  border-bottom: 1px solid rgba(73,19,70,0.80) ;
}

.teacher-dashboard-box-long.total-points {
  border-color: rgba(63,96,45,0.80) ;
}

.teacher-dashboard-box-long.total-points .dashboard-box-points-label {
  background: rgba(175,208,138,0.80);
  border-right: 1px solid rgba(63,96,45,0.80) ;
}

.teacher-dashboard-box-long.weekly-tip {
  border-color: rgba(71,25,25,0.80) ;
}

.teacher-dashboard-box-long.weekly-tip .dashboard-box-tip-label {
  background: rgba(231,155,116,0.80);
  border-right: 1px solid rgba(71,25,25,0.80) ;
}

.teacher-dashboard-graph.unit-graph {
  border-color:  rgba(40,76,73,0.80) ;
}

.teacher-dashboard-graph.unit-graph .dashboard-graph-label {
  background: rgb(142, 224, 215);
  border-bottom: 1px solid rgba(40,76,73,0.80) ;
}

.dashboard-students-enrolled{
  width: 100%;
  height: 40px;
  background-image: url(../assets/images/iconStudentsEnrolled.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center
}

.dashboard-students-weekly-usage{
  width: 100%;
  height: 40px;
  background-image: url(../assets/images/iconAverageWeeklyUsage.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center
}

.dashboard-students-weekly-time-goal{
  width: 100%;
  height: 40px;
  background-image: url(../assets/images/iconWeeklyTimeGoal.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center
}

.dashboard-students-not-started{
  width: 100%;
  height: 40px;
  background-image: url(../assets/images/iconStudentsNotStarted.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center
}

.dashboard-students-points-earned{
  height: 40px;
  background-image: url(../assets/images/iconTotalPointsEarned.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center
}

.ac-dashboard.weekly-usage-goal .met-goal {
  color: green;
}

.ac-dashboard.weekly-usage-goal .no-met-goal {
  color: red;
}

.y-axis-label{
  width: 77%;
  font-size: 11px;
  font-weight: bold;
  line-height: 105%;
  color: #4A4A4A;
}

.x-axis-label{
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  line-height: 1vh;
  color: #4A4A4A;
  position: relative;
  top: -2%;
}

.dashboard-box-label{
  width: 100%;
  height: 25%;
  text-align: center;
  display: inline;
  position: relative;
  top: -1vh;
  padding: 2px;
  font-size: 17px;
  line-height: 5vh;
  color: #4A4A4A;
}

.dashboard-box-not-started-label{
  width: 100%;
  height: 25%;
  text-align: center;
  display: inline;
  position: relative;
  top: -1vh;
  padding: 2px;
  font-size: 17px;
  line-height: 5vh;
  color: #4A4A4A;
}

.dashboard-tip{
  width: 80%;
  font-size: 14px;
  line-height: 150%;
  color: #4A4A4A;
  padding: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-box-points-label{
  text-align: center;
  display: flex;
  flex-direction: column ;
  justify-content: flex-start ;
  padding: 2%;
  font-size: 17px;
  line-height: 150%;
  color: #4A4A4A;
  width: 35% ;
}

.dashboard-box-tip-label{
  width: 20%;
  padding: 2%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  line-height: 150%;
  color: #4A4A4A;
}

.dashboard-graph-label{
  width: 100%;
  height: 15%;
  text-align: center;
  display: inline;
  font-size: 17px;
  line-height: 5vh;
  color: #4A4A4A;
  position: relative;
  top: -1%;
}

.teacher-dashboard-graph{
  height: 340px;
  width: 100%;
  display: flex;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  border: 5px solid #4A4A4A;
  border-radius: 10px;
  background-color: white;
}

.dashboard-bar-graph {
  height: 85%;
  width: 100%;
  margin: auto;
}

.teacher-dashboard-button{
  height: 150px;
  width: 150px;
  position: absolute;
  z-index: 1;
  border: transparent;
  background-color: transparent;

}

.teacher-dashboard-middle-grid {
  display: grid;
  grid-template-columns: 47.5% 47.5%;
  grid-column-gap: 5%;
}

.teacher-dashboard-grid {
  display: grid;
  grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
  grid-column-gap: 2%;
}

.teacher-dashboard-button:focus{
  outline: transparent;
}

.dashboard-box-number{
  width: 50%;
  font-size: 2.8rem;
  font-weight: bold;
  color: #4A4A4A;
  text-align: center;
}

.dashboard-box-points-number{
  font-size: 2.8rem;
  font-weight: bold;
  color: #4A4A4A;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65% ;
}

.dashboard-box-mini-label{
  width: 50%;
  font-size: 12px;
  color: #4A4A4A;
  text-align: center;
}

.dashboard-box-mini-goal-label{
  width: 100%;
  font-size: 12px;
  color: #4A4A4A;
  text-align: center;
}

.dashboard-dialog-title{
  line-height: 0px;
  padding-top: 6%;
  padding-left: 1.25vw;
  color: #4A4A4A;
}

.parent-dashboard-white-box{
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  border: 5px solid #F1BC41;
  border-radius: 20px;
  background-color: white;
  padding: 5px 20px 20px 20px;
}

.parent-dashboard-grey-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  border: 3px solid #4A90E2;
  border-radius: 10px;
  background-color:rgba(0, 0, 0, .07);
  padding: 5px 20px 20px 20px;
  margin-top: 10px;
}

.student-achievements {
  font-size: 24px;
  font-weight: 700;
  color: #104066;
  text-align: center ;
}

.parent-dashboard-minutes-area {
  min-height: 100px;
  width: 100%;
  display: flex;
  margin-top: 25px;
  justify-content: left;
  align-items: stretch;
  border: 5px solid #33663C;
  border-radius: 1vh;
  background-color: #7AA882;
}

.parent-dashboard-row-grid {
  display: grid;
  grid-template-columns: 17.75% 42.75% 36.5%;
  grid-column-gap: 1%;
  width: 100%
}

.parent-dashboard-white-label-large {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

.parent-dashboard-white-label {
  color: #ffffff;
  font-size: 13px;
}

.parent-dashboard-black-label {
  color: #000000;
  font-size: 13px;
  font-weight: 700;
}

.parent-dashboard-white-number-box {
  border: 2px solid #33663C;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin: 2px 0px 2px 0px;
}

.parent-dashboard-narrow-number-box {
  border: 2px solid #33663C;
  border-radius: 5px;
  background-color: rgb(255, 255, 255, 0.5);
  color: #4A4A4A;
  font-size: 18px;
  letter-spacing: 1.95px;
  font-weight: 700;
  text-align: center;
  margin: 2px 0px 2px 0px;
}

.parent-dashboard-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.parent-dashboard-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.parent-dashboard-goal-achieved-checkbox {
  background-image: url(../assets/images/parentDashboard/checkbox_goalAchievedGreen_bc.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  height: 45px;
  width: 45px;
}

.parent-dashboard-goal-achieved-text {
  color: #000000;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  position: relative;
  line-height: 1.0;
}

.parent-dashboard-level-area {
  min-height: 100px;
  width: 100%;
  display: flex;
  margin-top: 25px;
  justify-content: left;
  align-items: stretch;
  border: 5px solid #53568D;
  border-radius: 1vh;
  background-color: #9FA1C7;
}

.parent-dashboard-points-area {
  min-height: 150px;
  width: 100%;
  display: flex;
  margin-top: 25px;
  justify-content: left;
  align-items: stretch;
  border: 5px solid #4E779A;
  border-radius: 1vh;
  background-color: #A1C2DE;
}

.parent-dashboard-green-star {
  background-image: url(../assets/images/parentDashboard/parentDashboard_starGreen.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  display: block;
  position: relative;
  width: 75px;
  height: 75px;
  left: -27px;
  top: -30px;
}

.parent-dashboard-purple-star {
  background-image: url(../assets/images/parentDashboard/parentDashboard_starPurple.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  display: block;
  position: relative;
  width: 75px;
  height: 75px;
  left: -27px;
  top: -30px;
}

.parent-dashboard-blue-star {
  background-image: url(../assets/images/parentDashboard/parentDashboard_starBlue.svg);
  background-repeat: no-repeat;
  background-color: transparent;
  display: block;
  position: relative;
  width: 75px;
  height: 75px;
  left: -27px;
  top: -30px;
}

.school-teacher-filter {
  /*background-color: rgba(211, 211, 211, .4);*/
  color: black;
  /* override padding of diagnosticproduct-management-content so we can take up entire width*/
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.school-teacher-filter-label {
  font-size: 12px;
  font-weight: 800;
  color: black;
  padding-right: 5px;
}

.school-teacher-filter-dropdown-div {
  padding-right: 30px;
}

.school-teacher-filter-dropdown {
  font-size: 14px;
  color: black;
  padding: 0px 10px;
  min-width: 125px;
}

.school-teacher-filter-error-label {
  font-size: 16px;
  font-weight: 700;
  color: red;
}

.resolve-loader {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.25);
  color: white;
  z-index: 100;
  justify-content: center ;
  align-items: center;
  backdrop-filter: blur(2px);
  font-size: 2.5em;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", "Arial", "Helvetica", sans-serif;
  text-shadow: 0px 1px 3px black ;
}

.resolve-loader .loading-icon {
  height: 50px ;
  width: 50px ;
  margin-right: 5px ;
}

.decodingDiv{}
.generalizationDiv{}
.automaticityDiv{}
.print-show{ display: none !important; }

.drawerWrapper {
  display: inline-block ;
}

.header-controls {
  display: flex ;
  align-items: center ;
  margin-top: 20px ;
  margin-bottom: 10px ;
}

.header-controls label {
  margin-right: 10px ;
}

.header-controls .form-control {
  display: inline-block !important ;
  width: auto !important ;
}

.filter-options {
  display: flex ;
  flex-direction: row ;
  column-gap: 30px ;
}

h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--primary-color) ;
}

.large-label {
  color: #4A4A4A ;
  font-weight: bold ;
  font-size: 1rem ;
}

.large-stat-label {
  font-size: 1.25rem ;
  font-weight: bold ;
}

.small-instruction {
  font-size: 0.85rem ;
  color: #7F7F7F ;
}

.horizontal-gauge {
  background: linear-gradient(
    to right,
    var(--alert-border-color) 0%, 60%,
    var(--warn-border-color) 60% 80%,
    var(--accept-border-color) 80% 100%
  );
  border-radius: 3px ;
  height: 25px ;
}

.horizontal-gauge.warn {
  background: none ;
  background-color: var(--warn-border-color) ;
}

.horizontal-gauge.acceptable {
  background: none ;
  background-color: var(--accept-border-color) ;
}

.filled-gauge {
  height: 25px ;
}

.warn-color {
  color: var(--warn-border-color) ;
}

.alert-color {
  color: var(--alert-border-color) ;
}

.objective-dates {
  font-size: 0.75rem ;
  font-weight: bold ;
}

.objective-dates .pre {
  color: var(--pretest) ;
}

.objective-dates .post {
  color: var(--posttest) ;
}

#student-engagement-wrapper .title {
  background-color: #F8FAEC;
}

#student-engagement-wrapper .report-row {
  justify-content: center ;
}

#student-usage-progress-wrapper .title {
  background-color: #E8F1EB;
}

#student-usage-progress-wrapper .stat {
  background-color: #F2F2F2 ;
}

#student-performance-wrapper .title {
  background-color: #E7EEF5;
}

@media print {
  body { print-color-adjust: exact !important ;}
  .header{ display:none;}
  #sidebar{ display:none;}
  #hideFromPrint{ display:none;}
  #printButton{ display:none;}
  #tableHeader{ border:none; width: 100%}
  #contentarea{ width:100%;}
  .print-hide{ display: none !important; }
  .print-show{ display: block !important; }
  .full-table-border{ width: 100%;}
  .top-table-border{ width: 100%; height: 20pt;} /*summary*/
  .decoding-charts-section{ height: 250pt;}
  .generalization-charts-section{ height: 250pt;}
  .automaticity-charts-section{ height: 250pt;}
  .chart-background{ height: 290pt;}
  .chart-img{ height: 140pt;}
  .chart-bar{ margin-top: -175pt; padding: 10pt;}
  .chart-score { padding-bottom: 5pt;}
  .detail-brackets-img { margin-top: 0px;}
  .decoding-subhead-consonants { height: 18pt; margin-top: 10px;}
  .decoding-subhead-vowels { height: 18pt; margin-top: 10px;}
  .automaticity-subhead { margin-top: 20pt;}
  .compare-table-header{font-size: 0.5rem}
  h4{font-size: 1.5rem}
  .decodingDiv{margin-top: 10%;}
  .generalizationDiv{top: 15%;}
  .automaticityDiv{margin-top: 25%;}
  .generalization-subhead { margin-top: 20pt;}
  .instructional-priorities { font-size: 10pt !important;}
  .instructional-priority-links { margin-top: -10pt;}
  p1{ color:lightgray !important;}
  .chart-pie{ margin-top: -175pt; display: none;}
  .pie-legend{ font-size: 10pt;}
  .chart-img{ width: 100%; display: inline-block !important;}
  .screener-results-img { display: inline-block !important;}
  .pie-img{ display: block !important;}
  .diagnosticproduct-management-pie-placeholder{ visibility: hidden;}
  .diagnosticproduct-management-div-pie{ width: 80%;}
  .table-header-sortable{ text-decoration: none; }
  .legend-box { -webkit-print-color-adjust: exact; }
  .pretest-objective { background-color: #7E93EE !important; }
  .pretest-cumulative { background-color: #995895 !important; }
  .posttest-cumulative { background-color: #491446 !important; }
  .posttest-objective{ background-color: #1E3BB8 !important; }
  #categories #proficient, #categories #some-risk, #categories #high-risk { width: 175pt !important ; margin-left: 0.5cm !important ; }
  .title { font-size: 1rem !important ; }
  .stat-tile { gap: 10px !important; }
  .stat-value { font-size: 1.05em !important ; }
  .stat-label, .image-label { font-size: 0.75em !important; }
  .image-label.icon { padding-right: 0.75cm !important; padding-bottom: 0.75cm !important ; padding-top: 0.15cm !important; }
  .district-report-panel, .report-panel { border: none !important ; background: transparent ;}
  .district-report-column, .report-column { justify-content: space-between !important ; }
  .district-report-row, .report-row { gap: 0px !important ; }
  .district-report-table-wrapper { display: block !important ; }
  .district-report-table { margin-top: 0.5cm !important ; display: block !important; }
  .district-report-table th, .district-report-table td { font-size: 10pt !important ; }
  .district-report-content-container { border: none !important ; background: transparent !important; }
  .gauge-detail .summary { font-size: 9pt !important ; }
  #district-report-screener-enrollment { height: 75pt !important ; }
  #district-report-screener-completion { height: 150pt !important;}
  #district-report-screener-enrollment, #district-report-screener-completion { width: 200pt ; }
  .page-break-after { page-break-after: always ;}
  #performance-data { justify-content: space-between !important ; }
  #district-report-usage-trend { height: 100% !important ; }
  .usage-summary { width: 8.5cm !important; }
  #district-report-usage-enrollment, #district-report-usage-started { height: 100px ; }
  #district-report-usage-enrollment .stat-tile, #district-report-usage-started .stat-tile { margin-top: 5px !important ; }
  #usage-goal-data { flex-direction: column !important ; }
  #district-progress-predicted-completion, #district-progress-units { height: 200px !important ; }
  #progress-predicted-wrapper, #progress-units-wrapper { width: 50% !important ; }
  .chart-summary { margin-top: -25px !important ; margin-bottom: 25px !important ; }
  .diagnostic-performance-panel { margin: 0px !important ; border: none !important ; }
  .diagnostic-performance-panel .value-range { margin-top: -0.5cm !important ; }
  .diagnostic-progress-panel { font-size: 8pt !important ; background: transparent !important ; }
  .diagnostic-progress-info .display { margin-left: 0cm !important ; }
  .screener-report-chart { padding-left: 20px ; }
  .screener-results-score { background-position: center ; padding-left: 20px ; }
  .zone-explanation { font-size: 0.85rem ; width: 50% ; }
  .results-report-header { margin: 0 ; }
  .detail-table-section-header-alignment { height: 3vh ; }
  .report-container { border: 0 ; }
  .print-flex-column { flex-direction: column; flex-grow: 1; }
  .print-flex-100 { width:100% !important ; }
  .print-text-center { text-align: center !important; }
  fa-icon.print-icon svg { height: 17.5px ; width: 21px ; }
  html, body { height: auto; overflow: visible; }
}

.student-list-item {
  display: none;
}
.background-grey {
  background-color: #f7f7f7;
}

.hide-outside-mobile {
  display:none;
}

/** ChartJS Popup Styles **/
.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  padding: 5px ;
  background: rgba(0, 0, 0, .65);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip .chartjs-tooltip-header {
  font-weight: bold ;
  border-bottom: 1px solid #FFF ;
}

.chartjs-tooltip-key {
  border-color: rgba(255, 255, 255, 1) ;
  border-width: 2px ;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

ul.unit-students li {
  margin-left: -10px ;
}

.diagnosticproduct-management-div-pie-larger{
  width: 20vw;
  height: 20vh;
  margin-left: 0.5em;
}

@media screen and (max-width: 320px) {
  .hide-outside-mobile { display: block;}
  .hide-on-mobile{display: none !important;}
  .management-logout-button{ display: none;}
  .management-logout-button-mobile {
    background-image: url(../assets/images/logoutBttn.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    margin-right: 15px;
    height: 25px;
    width: 25px;
    border: 0px;
    outline: none;
    display: inline-block;
  }
  .diagnosticproduct-plus-school-container{display: none;}
  .fil-logo{ width: 75%}
  .fil-brand-wrapper { width: 60%; padding-left: 15px;}
  .hamburger-menu{display: inline-block;}
  .exit-menu{display: inline-block;}
  .diagnosticproduct-management-content {left: 0;}
  #sidebar-wrapper{z-index: 1; width: 70%;}
  .settings-input-text { width:85%; }
  .diagnosticproduct-management-table {display: none;}
  .full-table-border{ width: 100%;}
  .print-summary-button {display: none;}
  .diagnosticproduct.assessments-container {margin-bottom: 1em;}
  .submit-btn{
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }
  .mobile-select-item {width: 49%; height: 15vh; display: inline-block;}
  .student-list-item {
    height: 30vh;
    width: 100%;
    display: inline-flex;
    border: 1px solid #ddd;
  }
  .student-select-edit {
    padding: 2%;
    flex-direction: column;
    height: 90%;
    width: 21%;
    display: inline-flex;
    justify-content: space-around;
  }
  .student-select-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #4A4A4A;

  }
  .student-info {
    padding: 5px;
    height: 100%;
    width: 80%;
    display: inline-block;
    font-size: small;
  }
  .student-list-label {
    text-align: right;
    font-weight: bold;
    color: black;
    display: inline-flex;
    flex-direction: column;
    width: 45%;
    font-size: smaller;
  }
  .student-list-info {
    margin-left: 5px;
    text-align: left;
    color: black;
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    font-size: smaller;
  }
  .mobile-select-label {
    width: 100%;
    height: 50%;
    padding: 0 !important;
    text-align: center;
    margin-bottom: 10px !important;
  }
  .subtitle {
    font-weight: 100;
    font-size: x-small;
    color:#bfbfbf
  }
  .mobile-select {
    width: 100%;
    position: relative !important;
    margin:0 !important
  }
  .student-achievements{font-size: 20px;}
  .parent-dashboard-minutes-area{
    height:50vh;
    display: flex;
    flex-direction: column;
  }
  .parent-dashboard-level-area{height:50vh;}
  .parent-dashboard-points-area{height:50vh;}
  .parent-dashboard-row-grid{display:none;}
  .parent-dashboard-label-wrapper{
    width: 100%;
    height: 10%;
  }
  .parent-dashboard-green-star{
    display: inline-block;
    background-size: contain;
    width: 50px;
    height: 50px;
    left: -25px;
    top: -25px;}
  .parent-dashboard-purple-star{
    display: inline-block;
    background-size: contain;
    width: 50px;
    height: 50px;
    left: -25px;
    top: -25px;}
  .parent-dashboard-blue-star{
    display: inline-block;
    background-size: contain;
    width: 50px;
    height: 50px;
    left: -25px;
    top: -25px;}
  .parent-dashboard-white-label-large{
    display: inline-block;
    width: 50%;
    position: absolute;
    font-size: small;
  }
  .parent-dashboard-black-label{
    text-align: center;
    font-size: x-small;
  }
  .parent-dashboard-white-label{
    font-size: xx-small;
  }
  .parent-dashboard-narrow-number-box {
    font-size: small;
  }
  .parent-dashboard-white-number-box {
    font-size: small;
  }
  .parent-dashboard-goal-achieved-checkbox{
    background-size: contain;
    width: 30px;
    height: 30px;
  }
  .parent-dashboard-goal-achieved-text{
    font-size: x-small;
  }
  .parent-dashboard-item{
    margin-top: 7%;
    display: inline-flex;
    width: 100%;
    height: 25%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  .hide-outside-mobile { display: block;}
  .hide-on-mobile{display: none !important;}
  .management-logout-button{ display: none;}
  .management-logout-button-mobile {
    background-image: url(../assets/images/logoutBttn.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    margin-right: 15px;
    height: 25px;
    width: 25px;
    border: 0px;
    outline: none;
    display: inline-block;
  }
  .diagnosticproduct-plus-school-container{display: none;}
  .fil-logo{ width: 75%}
  .fil-brand-wrapper { width: 60%; padding-left: 15px;}
  .hamburger-menu{display: inline-block;}
  .exit-menu{display: inline-block;}
  .diagnosticproduct-management-content {left: 0;}
  #sidebar-wrapper{z-index: 1; width: 70%;}
  .settings-input-text { width:85%; }
  .diagnosticproduct-management-table {display: none;}
  .full-table-border{ width: 100%;}
  .print-summary-button {display: none;}
  .diagnosticproduct.assessments-container {margin-bottom: 1em;}
  .submit-btn{
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }
  .mobile-select-item {width: 49%; height: 10vh; display: inline-block;}
  .student-list-item {
    height: 30vh;
    width: 100%;
    display: inline-flex;
    border: 1px solid #ddd;
  }
  .student-select-edit {
    padding: 2%;
    flex-direction: column;
    height: 90%;
    width: 21%;
    display: inline-flex;
    justify-content: space-around;
  }
  .student-select-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #4A4A4A;

  }
  .student-info {
    padding: 5px;
    height: 100%;
    width: 80%;
    display: inline-block;
    font-size: small;
  }
  .student-list-label {
    text-align: right;
    font-weight: bold;
    color: black;
    display: inline-flex;
    flex-direction: column;
    width: 45%;
    font-size: smaller;
  }
  .student-list-info {
    margin-left: 5px;
    text-align: left;
    color: black;
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    font-size: smaller;
  }
  .mobile-select-label {
    width: 100%;
    height: 50%;
    padding: 0 !important;
    text-align: center;
  }
  .subtitle {
    font-weight: 100;
    font-size: small;
    color:#bfbfbf
  }
  .mobile-select {
    width: 100%;
    position: relative !important;
    margin:0 !important
  }
  .student-achievements{font-size: 20px;}
  .parent-dashboard-minutes-area{
    height:50vh;
    display: flex;
    flex-direction: column;
  }
  .parent-dashboard-level-area{height:50vh;}
  .parent-dashboard-points-area{height:50vh;}
  .parent-dashboard-row-grid{display:none;}
  .parent-dashboard-label-wrapper{
    width: 100%;
    height: 10%;
  }
  .parent-dashboard-green-star{display: inline-block;}
  .parent-dashboard-purple-star{display: inline-block;}
  .parent-dashboard-blue-star{display: inline-block;}
  .parent-dashboard-white-label-large{
    display: inline-block;
    width: 50%;
    position: absolute;
  }
  .parent-dashboard-black-label{
    text-align: center;
  }
  .parent-dashboard-item{
    display: inline-flex;
    width: 100%;
    height: 30%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 820px) and (min-width: 480px) {
  .hide-outside-mobile { display: block; width: 100%;}
  .hide-on-mobile{display: none !important;}
  .management-logout-button{ display: none;}
  .management-logout-button-mobile {
    background-image: url(../assets/images/logoutBttn.svg);
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    margin-right: 15px;
    height: 25px;
    width: 25px;
    border: 0px;
    outline: none;
    display: inline-block;
  }
  .diagnosticproduct-plus-school-container{display: none;}
  .fil-logo{ width: 75%}
  .fil-brand-wrapper { width: 60%; padding-left: 15px;}
  .hamburger-menu{display: inline-block;}
  .exit-menu{display: inline-block;}
  .diagnosticproduct-management-content {left: 0;}
  #sidebar-wrapper{z-index: 1; width: 50%;}
  .settings-input-text { width:85%; }
  .diagnosticproduct-management-table {display: none;}
  .full-table-border{ width: 100%;}
  .print-summary-button {display: none;}
  .diagnosticproduct.assessments-container {margin-bottom: 1em;}
  .submit-btn{
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }
  .mobile-select-item {width: 49%; height: 25vh; display: inline-block;}
  .student-list-item {
    height: 50vh;
    width: 100%;
    display: inline-flex;
    border: 1px solid #ddd;
  }
  .student-select-edit {
    padding: 5%;
    flex-direction: column;
    height: 90%;
    width: 25%;
    display: inline-flex;
    justify-content: space-around;
  }
  .student-select-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    color: #4A4A4A;

  }
  .student-info {
    padding: 5px;
    height: 100%;
    width: 80%;
    display: inline-block;
    font-size: small;
  }
  .student-list-label {
    text-align: right;
    font-weight: bold;
    color: black;
    display: inline-flex;
    flex-direction: column;
    width: 45%;
    font-size: smaller;
  }
  .student-list-info {
    margin-left: 5px;
    text-align: left;
    color: black;
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    font-size: smaller;
  }
  .mobile-select-label {
    width: 100%;
    height: 50%;
    padding: 0 !important;
    text-align: center;
  }
  .subtitle {
    font-weight: 100;
    font-size: small;
    color:#bfbfbf
  }
  .mobile-select {
    width: 100%;
    position: relative !important;
    margin:0 !important
  }
  .student-achievements{font-size: 20px;}
  .parent-dashboard-minutes-area{
    height:90vh;
    display: flex;
    flex-direction: column;
  }
  .parent-dashboard-level-area{
    height:auto;
    display: flex;
    flex-direction: column;
  }
  .parent-dashboard-points-area{height:90vh;}
  .parent-dashboard-row-grid{display:none;}
  .parent-dashboard-label-wrapper{
    width: 100%;
    height: 10%;
  }
  .parent-dashboard-green-star{display: inline-block;}
  .parent-dashboard-purple-star{display: inline-block;}
  .parent-dashboard-blue-star{display: inline-block;}
  .parent-dashboard-white-label-large{
    display: inline-block;
    width: 50%;
    position: absolute;
  }
  .parent-dashboard-item{
    margin-top: 2%;
    display: inline-flex;
    width: 100%;
    height: 25%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 820px) {
  .sidebar-wrapper {
    display: none ;
  }
}

@media screen and (max-width: 1200px) {
  .diagnosticproduct-management-div-pie {
    height: 20vh;
    width: 12vw;
  }

  .diagnosticproduct-management-div-pie-larger {
    min-height: 20vh;
    min-width: 16vw;
  }

  .dashboard-box-label {
    font-size: 0.95rem ;
  }

  .dashboard-box-points-number {
    font-size: 2.25rem ;
  }
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
label {
  font-weight: bold ;
}
small {
  font-size: 0.95rem ;
}
ul.no-bullet {
  list-style: none ;
}
